import { mainAppState } from '~/state/mainAppState';
import { BaseNetworkManager } from './baseNetworkManager';
import { CompanyIdProvider } from './companyIdProvider';
import { MagicRequest } from './magicRequest';
import { NetworkManager } from './networkManager';

class CompanyIdAttachingNetworkManager implements NetworkManager {
  private networkManager: NetworkManager;
  private companyIdProvider: CompanyIdProvider;

  constructor(companyIdProvider: CompanyIdProvider, networkManager: NetworkManager) {
    this.networkManager = networkManager;
    this.companyIdProvider = companyIdProvider;
  }

  public sendRequest = async (request: MagicRequest): Promise<Response> => {
    let newRequest = request;
    const companyId = this.companyIdProvider.getCompanyId();
    if (companyId) {
      const newHeaders: Headers = request.headers;
      newHeaders.append('X-Company-Id', `${companyId}`);
      newRequest = {
        ...request,
        headers: newHeaders,
      };
    }
    return await this.networkManager.sendRequest(newRequest);
  };
}

export const companyIdAttachingNetworkManager = new CompanyIdAttachingNetworkManager(mainAppState, new BaseNetworkManager());
